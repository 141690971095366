import React from 'react';

export type AllocationPanelContextValue = {
  allocationPanelType: 'full' | 'historical read-only';
};

export const defaultAllocationPanelContextValue: AllocationPanelContextValue = {
  allocationPanelType: 'full',
};

export const AllocationPanelContext = React.createContext<AllocationPanelContextValue>(
  defaultAllocationPanelContextValue,
);
AllocationPanelContext.displayName = 'AllocationPanelContext';
