import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import type { Portfolio } from 'venn-api';
import { createPortfolioAsMasterV3, createPortfolioV3 } from 'venn-api';
import { Icon, Label, LoadingSize, MASTER_PORTFOLIO_ARTICLE_HREF, Spinner } from 'venn-ui-kit';
import {
  assertNotNil,
  logExceptionIntoSentry,
  recursiveUpdateAllocation,
  recursiveUpdatePortfolioName,
  useHasFF,
} from 'venn-utils';
import { ConditionalOverlay } from '../../conditional-overlay';
import PortfoliosContext from '../../contexts/portfolios-context';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '../../modal';
import SliderToggle from '../../slider-toggle/SliderToggle';
import Name from './Name';
import type { NameField } from './types';
import { OwnershipContextSelector } from '../components/OwnershipContextSelector';
import { omitBy, isNull, isNil } from 'lodash';
import { UserContext } from '../../contexts';
import { getGlobalContext } from '../../utils';

interface CreatePortfolioModalProps {
  portfolio: Portfolio;
  isPercentageMode: boolean;
  total: number | undefined;
  onClose: () => void;
  onSubmitCallback?: (newPortfolio: Portfolio | undefined) => void;
  populateName: boolean;
  isStudio?: boolean;
}

const CreatePortfolioModal = ({
  portfolio,
  isPercentageMode,
  total,
  onClose,
  onSubmitCallback,
  populateName,
  isStudio,
}: CreatePortfolioModalProps) => {
  const { currentContext, hasPermission, profileSettings } = useContext(UserContext);
  const globalContextId = getGlobalContext(profileSettings)?.value.context.id;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [nameField, setNameField] = useState<NameField>({
    validating: true,
    isError: false,
  });
  const { masterPortfolio } = useContext(PortfoliosContext);
  /* If the user doesn't have master, force setMaster to true */
  const hasMaster = !isNil(masterPortfolio);
  const [createAsMaster, setCreateAsMaster] = useState<boolean>(!hasMaster);
  const [ownerContextId, setOwnerContextId] = useState<string | undefined>(
    createAsMaster ? globalContextId : currentContext,
  );

  const setMasterToggle = useCallback(() => {
    // disallow user to toggle master setting if missing master portfolio
    if (!hasMaster) {
      return;
    }
    const newCreateAsMaster = !createAsMaster;
    setCreateAsMaster(newCreateAsMaster);
    setOwnerContextId(newCreateAsMaster ? globalContextId : currentContext);
  }, [createAsMaster, currentContext, globalContextId, hasMaster]);

  const onSubmit = useCallback(async () => {
    const { value } = nameField;
    setSubmitting(true);

    const request = createAsMaster ? createPortfolioAsMasterV3 : createPortfolioV3;
    try {
      let updatedPortfolio = recursiveUpdatePortfolioName(portfolio);
      if (isPercentageMode && total !== portfolio.allocation) {
        updatedPortfolio = recursiveUpdateAllocation(updatedPortfolio, assertNotNil(total));
      }
      const newPortfolio = (
        await request({
          ...updatedPortfolio,
          id: -1,
          name: value,
          optimizationId: undefined,
          draft: false,
          demo: false,
          master: createAsMaster,
          ...omitBy({ ownerContextId }, isNull),
        })
      ).content;
      onSubmitCallback?.(newPortfolio);
    } catch (error) {
      const e = await error;
      logExceptionIntoSentry(e);
      onSubmitCallback?.(undefined);
    }

    setSubmitting(false);
    onClose();
  }, [createAsMaster, isPercentageMode, nameField, onClose, onSubmitCallback, portfolio, total, ownerContextId]);

  const enabled = !nameField.isError && !nameField.validating && !submitting;
  const loading = nameField.validating || submitting;

  const hasContextSwitching = useHasFF('context_switching');
  return (
    <Modal onClose={onClose}>
      <ConditionalOverlay condition={submitting}>
        <ModalHeader>Save New Portfolio</ModalHeader>
        <ModalContent>
          {isStudio &&
            `All instances of ${portfolio.name} in this view will be replaced by the newly created portfolio.`}
          <Name
            onChange={setNameField}
            initialValue={populateName ? (portfolio.draft ? portfolio.name : `${portfolio.name} (Copy)`) : undefined}
          />

          {hasContextSwitching && (
            <OwnershipContextSelector
              disabled={createAsMaster}
              requiredAction="CREATE_PORTFOLIO"
              ownerContextId={ownerContextId}
              setOwnerContextId={setOwnerContextId}
            />
          )}
          {hasPermission('MANAGE_MASTER_PORTFOLIO') && (
            <>
              <LabelContainer>
                <Label className="qa-dropmenu-input-label">
                  Set master portfolio:{' '}
                  <StyledInfoLink target="_blank" rel="noopener noreferrer" href={MASTER_PORTFOLIO_ARTICLE_HREF}>
                    <StyledInfoIcon type="info-circle" prefix="fas" />
                  </StyledInfoLink>{' '}
                </Label>
              </LabelContainer>
              <ToggleContainer>
                <SliderToggle toggled={createAsMaster} onToggle={setMasterToggle} disabled={!hasMaster} />
              </ToggleContainer>
            </>
          )}
        </ModalContent>
        <StyledFooter
          onCancel={onClose}
          primaryLabel="Save"
          primaryClassName="qa-create-portfolio"
          primaryDisabled={!enabled}
          rightChildren={loading && <Spinner size={LoadingSize.small} />}
          onPrimaryClick={onSubmit}
        />
      </ConditionalOverlay>
    </Modal>
  );
};

export default CreatePortfolioModal;

const StyledFooter = styled(ModalFooter)`
  .qa-create-portfolio {
    width: 175px;
  }
`;

const LabelContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
`;

const ToggleContainer = styled.div`
  width: 80px;
`;

const StyledInfoLink = styled.a`
  color: inherit;
`;

const StyledInfoIcon = styled(Icon)`
  margin-left: 3px;
`;
