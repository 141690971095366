import { useHasFF } from 'venn-utils';
import { StudioAllocatorSubject } from './StudioAllocatorSubject';
import { Constants } from '../Layout';
import React from 'react';
import { StudioAllocatorWindow } from './components';

interface ShimmerPanelProps {
  isPrivate?: boolean;
}
export const StudioAllocationPanelShimmerBlock = ({ isPrivate }: ShimmerPanelProps) => {
  const hasApInRl = useHasFF('ap_in_rl_ff');
  return (
    <StudioAllocatorWindow hasApInRl={hasApInRl}>
      <StudioAllocatorSubject isPrivate={isPrivate} width={Constants.ALLOCATION_PANEL_TOTAL_WIDTH + 1} disabled />
    </StudioAllocatorWindow>
  );
};
