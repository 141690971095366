import { useEffect, useMemo, useState } from 'react';
import { analyticsService, assertNotNil } from 'venn-utils';
import { checkHasQuantDiff } from '../AllocationUtils';
import type { ItemAllocationProps, ItemPercentageProps } from '../ItemAllocation';
import type { ItemAllocationContextValue } from '../contexts/ItemAllocationContext';

function parseNumber(value?: string): number | undefined {
  if (value === null || value === undefined) {
    return undefined;
  }
  const numeric = parseFloat(value.replace(',', '.'));
  return Number.isNaN(numeric) ? undefined : numeric;
}

export type UseItemAllocationProps = Pick<
  ItemAllocationProps,
  | 'isPercentageMode'
  | 'baseAllocation'
  | 'orignalBaseAllocation'
  | 'value'
  | 'isGhost'
  | 'isStrategy'
  | 'onUpdateAllocation'
  | 'originalValue'
  | 'isOutsideOfSelectedSubtree'
  | 'isRoot'
>;

const maybeConvertToPercentageMode = ({
  itemPercentageProps,
  value,
}: {
  itemPercentageProps: ItemPercentageProps;
  value: number | undefined;
}) => {
  const updatedValue = itemPercentageProps.isPercentageMode
    ? ((value ?? 0) * 100) / itemPercentageProps.baseAllocation
    : value;
  return value !== null && value !== undefined && updatedValue !== null && updatedValue !== undefined
    ? updatedValue.toString()
    : '0.0';
};

export const useItemAllocation = ({
  isPercentageMode,
  baseAllocation,
  orignalBaseAllocation,
  value,
  isGhost,
  isStrategy,
  onUpdateAllocation,
  originalValue,
  isOutsideOfSelectedSubtree,
  isRoot,
}: UseItemAllocationProps): ItemAllocationContextValue => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isFocusedGhostInput, setIsFocusedGhostInput] = useState<boolean>(false);

  const itemPercentageProps: ItemPercentageProps = useMemo(
    () =>
      isPercentageMode
        ? {
            isPercentageMode,
            baseAllocation: assertNotNil(baseAllocation),
            originalBaseAllocation: orignalBaseAllocation,
          }
        : {
            isPercentageMode,
          },
    [isPercentageMode, baseAllocation, orignalBaseAllocation],
  );
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    maybeConvertToPercentageMode({ itemPercentageProps, value }),
  );

  useEffect(() => {
    if (!isEditing) {
      setCurrentValue(maybeConvertToPercentageMode({ itemPercentageProps, value }));
    }
  }, [isEditing, itemPercentageProps, value]);

  const onChange = (value: string) => {
    setCurrentValue(value);
    setIsEditing(true);
  };

  const onFocus = () => {
    if (isGhost && !isStrategy) {
      setIsFocusedGhostInput(true);
    }
  };

  const onBlur = () => {
    setIsEditing(false);

    const numericValue = parseNumber(currentValue) || 0;
    const isAddingGhostFund = isGhost && !isStrategy;
    const shouldUpdate = isAddingGhostFund
      ? numericValue !== 0
      : itemPercentageProps.isPercentageMode
        ? numericValue !== ((value ?? 0) * 100) / itemPercentageProps.baseAllocation
        : numericValue !== value;

    if (shouldUpdate) {
      const updatedValue = itemPercentageProps.isPercentageMode
        ? (numericValue * itemPercentageProps.baseAllocation) / 100
        : numericValue;
      analyticsService.textFieldUpdated({
        locationOnPage: 'Allocator Panel',
      });
      onUpdateAllocation(updatedValue);
      setCurrentValue(updatedValue.toString());
    }

    if (isAddingGhostFund) {
      setIsFocusedGhostInput(false);
    }
  };

  const canAddFundFromGhost = !isStrategy && isGhost && !isOutsideOfSelectedSubtree;
  const isNonGhostFundWithinSelectedSubtree = !isStrategy && !isGhost && !isOutsideOfSelectedSubtree;
  const allocation =
    value !== null && value !== undefined
      ? itemPercentageProps.isPercentageMode
        ? (value * 100) / itemPercentageProps.baseAllocation
        : value
      : 0;
  const invalidRoot =
    itemPercentageProps.isPercentageMode &&
    isRoot &&
    checkHasQuantDiff((value ?? 0) / itemPercentageProps.baseAllocation, 1, 1000);
  const difference = itemPercentageProps.isPercentageMode
    ? ((value || 0) * 100) / itemPercentageProps.baseAllocation -
      ((originalValue || 0) * 100) / (itemPercentageProps.originalBaseAllocation || 1)
    : (value || 0) - (originalValue || 0);

  return {
    currentValue,
    isEditing,
    isFocusedGhostInput,
    itemPercentageProps,
    onChange,
    onFocus,
    onBlur,
    canAddFundFromGhost,
    isNonGhostFundWithinSelectedSubtree,
    allocation,
    invalidRoot,
    difference,
  };
};
