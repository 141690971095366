import React from 'react';
import styled from 'styled-components';
import { Icon, GetColor } from 'venn-ui-kit';

interface HandleProps {
  showIcon: boolean;
  height: number;
  iconOpacity?: number;
  iconColor?: string;
  className?: string;
  onMouseDown?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

const Handle: React.FC<React.PropsWithChildren<HandleProps>> = ({ showIcon, className, onMouseDown, ...iconProps }) => {
  if (!showIcon) {
    return <WhiteSpace {...iconProps} />;
  }

  return (
    <WhiteSpace className={className} onMouseDown={onMouseDown} {...iconProps}>
      <Icon iconLabel="drag-and-drop handle" type="arrows-alt-v" />
    </WhiteSpace>
  );
};

const WhiteSpace = styled.span<{ height: number; iconOpacity?: number; iconColor?: string }>`
  width: 16px;
  padding: 7px 5px;
  height: ${({ height }) => height}px;
  line-height: ${({ height }) => height}px;
  color: ${({ iconColor }) => iconColor || GetColor.Black};
  user-select: none;

  &:not(:empty) {
    cursor: grab;
  }

  i {
    ${({ iconOpacity }) => iconOpacity !== undefined && `opacity: ${iconOpacity};`}
    line-height: ${({ height }) => height}px;
  }
`;

export default Handle;
