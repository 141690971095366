import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export const StudioAllocatorWindow = styled.div<{
  hasApInRl: boolean;
}>`
  height: 100%;
  background-color: ${GetColor.White};
  ${({ hasApInRl }) =>
    hasApInRl
      ? css`
          border-left: 1px solid ${GetColor.GreyScale.Grey30};
          border-right: 3px solid ${GetColor.GreyScale.Grey30};
        `
      : css`
          border-left: 1px solid ${GetColor.Grey};
          border-right: 1px solid ${GetColor.Grey};
        `}

  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-areas:
    'allocator-header'
    'allocator-subject'
    'allocator-panel';
  grid-template-columns: 1fr;
  grid-template-rows: 0 auto 1fr;
`;

export const EmptyStudioAllocatorWindow = styled(StudioAllocatorWindow)`
  /* The empty allocator doesn't have a separate header. */
  grid-template-areas:
    'allocator-subject'
    'allocator-panel';
  grid-template-rows: auto 1fr;
  width: 100%;
`;

export const StudioAllocatorSubjectSection = styled.div`
  grid-area: allocator-subject;
  padding: 12px 8px;
  background-color: ${GetColor.White};
  width: 100%;
`;
