import React, { useContext } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { GetColor, TooltipPosition } from 'venn-ui-kit';
import { Constants } from './Layout';
import AllocationDifferenceTooltip from './AllocationDifferenceTooltip';
import KeyBlockingNumericInput from '../key-blocking-numeric-input/KeyBlockingNumericInput';
import { formatAllocation } from 'venn-utils';
import { ItemAllocationContext } from './contexts/ItemAllocationContext';
import { useItemAllocation, type UseItemAllocationProps } from './hooks/useItemAllocation';

export const IncreasedSpecificityGhostFundWrapperClass = 'allocation-input-value-wrapper';
export const GhostFundAllocationInputClass = 'ghost-fund-allocation-input';
export const GhostFundAllocationValueClass = 'ghost-fund-allocation-value';
const InvalidRootValue = 'invalid-root-value';

// this interface is defined incorrectly. use ItemPercentageProps below instead.
export interface DeprecatedIncorrectlyDefinedItemPercentageProps {
  isPercentageMode: boolean;
  // For percentage calculation
  baseAllocation?: number;
  // For calculate delta
  orignalBaseAllocation?: number;
}

export type ItemPercentageProps =
  | {
      isPercentageMode: true;
      baseAllocation: number;
      originalBaseAllocation: number | undefined;
    }
  | {
      isPercentageMode: false;
    };

export interface ItemAllocationProps extends DeprecatedIncorrectlyDefinedItemPercentageProps {
  value?: number;
  originalValue?: number;
  isGhost: boolean;
  isStrategy: boolean;
  isRoot: boolean;
  isModified: boolean;
  isOutsideOfSelectedSubtree: boolean;
  onUpdateAllocation: (value: number) => void;
  usePortalForTooltip?: boolean;
  className?: string;
}

type ItemAllocationPrimitivesProviderProps = UseItemAllocationProps & { children: React.ReactNode };
type ItemAllocationPrimitivesRootProps = Pick<
  ItemAllocationProps,
  | 'value'
  | 'isModified'
  | 'isRoot'
  | 'isGhost'
  | 'isStrategy'
  | 'isOutsideOfSelectedSubtree'
  | 'usePortalForTooltip'
  | 'className'
> & { children: React.ReactNode };
type ItemAllocationPrimitivesInputProps = Pick<ItemAllocationProps, 'isModified' | 'isGhost' | 'isStrategy'>;

export const ItemAllocationPrimitives = {
  Provider: ({
    isPercentageMode,
    baseAllocation,
    orignalBaseAllocation,
    value,
    isGhost,
    isStrategy,
    onUpdateAllocation,
    originalValue,
    isOutsideOfSelectedSubtree,
    isRoot,
    children,
  }: ItemAllocationPrimitivesProviderProps) => {
    const contextValue = useItemAllocation({
      isPercentageMode,
      baseAllocation,
      orignalBaseAllocation,
      value,
      isGhost,
      isStrategy,
      onUpdateAllocation,
      originalValue,
      isOutsideOfSelectedSubtree,
      isRoot,
    });
    return <ItemAllocationContext.Provider value={contextValue}>{children}</ItemAllocationContext.Provider>;
  },
  Root: ({
    value,
    isModified,
    isRoot,
    isGhost,
    isStrategy,
    isOutsideOfSelectedSubtree,
    usePortalForTooltip,
    className,
    children,
  }: ItemAllocationPrimitivesRootProps) => {
    const {
      isEditing,
      isFocusedGhostInput,
      itemPercentageProps,
      canAddFundFromGhost,
      isNonGhostFundWithinSelectedSubtree,
      allocation,
      invalidRoot,
      difference,
    } = useContext(ItemAllocationContext);
    return (
      <AllocationDifferenceTooltip
        className={className}
        usePortal={usePortalForTooltip}
        hasDifference={isModified && !isEditing}
        value={allocation}
        difference={difference}
        position={isRoot ? TooltipPosition.Bottom : TooltipPosition.Top}
        isPercentageMode={itemPercentageProps.isPercentageMode}
        hidden={isEditing}
      >
        <ValueWrapper
          className={canAddFundFromGhost ? IncreasedSpecificityGhostFundWrapperClass : 'item-allocation-value-wrapper'}
          isRoot={isRoot}
          isStrategy={isStrategy}
          isModified={isModified && !isGhost}
          isFocusedGhostInput={isFocusedGhostInput}
          isGreyedOut={isOutsideOfSelectedSubtree || isGhost}
        >
          {(canAddFundFromGhost || isNonGhostFundWithinSelectedSubtree) && children}
          {(isGhost || isStrategy || isOutsideOfSelectedSubtree) && (
            <Value
              className={
                !isStrategy && isGhost ? GhostFundAllocationValueClass : invalidRoot ? InvalidRootValue : undefined
              }
            >
              {value !== null && value !== undefined
                ? formatAllocation(
                    value,
                    isRoot,
                    itemPercentageProps.isPercentageMode,
                    itemPercentageProps.isPercentageMode ? itemPercentageProps.baseAllocation : undefined,
                  )
                : '--'}
            </Value>
          )}
        </ValueWrapper>
      </AllocationDifferenceTooltip>
    );
  },
  Input: ({ isModified, isGhost, isStrategy }: ItemAllocationPrimitivesInputProps) => {
    const { currentValue, itemPercentageProps, canAddFundFromGhost, onChange, onFocus, onBlur } =
      useContext(ItemAllocationContext);
    const { Colors } = useTheme();
    return (
      <KeyBlockingNumericInput
        className={canAddFundFromGhost ? GhostFundAllocationInputClass : 'key-blocking-numeric-input'}
        isLocked={isGhost && isStrategy}
        value={currentValue}
        onChange={onChange}
        onFocus={onFocus}
        onCommitInput={onBlur}
        isPercentage={itemPercentageProps.isPercentageMode}
        height={20}
        defaultBorderColor={isModified ? Colors.HighlightDark : Colors.Grey}
        textColor={isModified ? Colors.HighlightDark : Colors.Black}
        allowNegative={false}
      />
    );
  },
  DisabledInput: ({ isModified }: Pick<ItemAllocationPrimitivesInputProps, 'isModified'>) => {
    const { currentValue, itemPercentageProps, canAddFundFromGhost, onChange, onFocus, onBlur } =
      useContext(ItemAllocationContext);
    const { Colors } = useTheme();
    return (
      <KeyBlockingNumericInput
        className={canAddFundFromGhost ? GhostFundAllocationInputClass : 'key-blocking-numeric-input'}
        isLocked
        value={currentValue}
        onChange={onChange}
        onFocus={onFocus}
        onCommitInput={onBlur}
        isPercentage={itemPercentageProps.isPercentageMode}
        height={20}
        defaultBorderColor={isModified ? Colors.HighlightDark : Colors.Grey}
        textColor={isModified ? Colors.HighlightDark : Colors.Black}
        allowNegative={false}
      />
    );
  },
};
export const ItemAllocation = ({
  value,
  originalValue,
  isModified,
  isRoot,
  isGhost,
  isStrategy,
  isOutsideOfSelectedSubtree,
  onUpdateAllocation,
  usePortalForTooltip,
  className,
  isPercentageMode,
  baseAllocation,
  orignalBaseAllocation,
}: ItemAllocationProps) => {
  return (
    <ItemAllocationPrimitives.Provider
      isPercentageMode={isPercentageMode}
      baseAllocation={baseAllocation}
      orignalBaseAllocation={orignalBaseAllocation}
      value={value}
      isGhost={isGhost}
      isStrategy={isStrategy}
      onUpdateAllocation={onUpdateAllocation}
      originalValue={originalValue}
      isOutsideOfSelectedSubtree={isOutsideOfSelectedSubtree}
      isRoot={isRoot}
    >
      <ItemAllocationPrimitives.Root
        value={value}
        isModified={isModified}
        isRoot={isRoot}
        isGhost={isGhost}
        isStrategy={isStrategy}
        isOutsideOfSelectedSubtree={isOutsideOfSelectedSubtree}
        usePortalForTooltip={usePortalForTooltip}
        className={className}
      >
        <ItemAllocationPrimitives.Input isModified={isModified} isGhost={isGhost} isStrategy={isStrategy} />
      </ItemAllocationPrimitives.Root>
    </ItemAllocationPrimitives.Provider>
  );
};

function getTextColor(isModified: boolean, isGreyedOut: boolean) {
  if (isModified) {
    return isGreyedOut ? GetColor.HighlightLight : GetColor.HighlightDark;
  }
  return isGreyedOut ? GetColor.Grey : GetColor.Black;
}

export const ValueWrapper = styled.div<{
  isRoot: boolean;
  isStrategy: boolean;
  isModified: boolean;
  isFocusedGhostInput: boolean;
  isGreyedOut: boolean;
}>`
  ${({ isRoot }) => css`
    font-size: ${isRoot ? 16 : 14}px;
  `}
  ${({ isModified, isGreyedOut }) => css`
    color: ${getTextColor(isModified, isGreyedOut)};
  `}
  ${({ isStrategy }) =>
    isStrategy &&
    css`
      font-weight: bold;
    `}
  width: ${Constants.ALLOCATION_WIDTH}px;
  min-width: ${Constants.ALLOCATION_WIDTH}px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  ${({ isFocusedGhostInput }) => `
    .${GhostFundAllocationInputClass} {
      display: ${isFocusedGhostInput ? 'block' : 'none'};
    }
    .${GhostFundAllocationValueClass} {
      display: ${isFocusedGhostInput ? 'none' : 'block'};
    }
  `}
`;

export const Value = styled.div`
  padding-right: 5px;
  &.invalid-root-value {
    color: ${GetColor.Error};
    font-style: italic;
  }
`;
