import React from 'react';
import type { ItemPercentageProps } from '../ItemAllocation';

export type ItemAllocationContextValue = {
  currentValue?: string;
  isEditing: boolean;
  isFocusedGhostInput: boolean;
  itemPercentageProps: ItemPercentageProps;
  onChange: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  canAddFundFromGhost: boolean;
  isNonGhostFundWithinSelectedSubtree: boolean;
  allocation: number;
  invalidRoot: boolean;
  difference: number;
};

const defaultItemAllocationContextValue: ItemAllocationContextValue = {
  currentValue: undefined,
  isEditing: false,
  isFocusedGhostInput: false,
  itemPercentageProps: { isPercentageMode: false },
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  canAddFundFromGhost: false,
  isNonGhostFundWithinSelectedSubtree: false,
  allocation: 0,
  invalidRoot: false,
  difference: 0,
};

export const ItemAllocationContext = React.createContext<ItemAllocationContextValue>(defaultItemAllocationContextValue);
ItemAllocationContext.displayName = 'ItemAllocationContext';
