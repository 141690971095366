import { useRecoilState, useRecoilValue } from 'recoil';
import { compareColumnOpen, isReportState } from 'venn-state';
import React, { useContext } from 'react';
import { AllocationPanelContext } from '../AllocationPanelContext';
import StudioAllocatorSubjectSelector from './StudioAllocatorSubjectSelector';
import { StudioAllocatorSubjectSection } from './components';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { ClosePanelButton } from '../../side-panel-settings/ClosePanelButton';

interface StudioAllocatorSubjectProps {
  width: number;
  disabled: boolean;
  isPrivate?: boolean;
  hideCompareLink?: boolean;
  onClose?: () => void;
}

export const StudioAllocatorSubject = ({
  width,
  disabled,
  isPrivate = false,
  hideCompareLink = false,
  onClose,
}: StudioAllocatorSubjectProps) => {
  const isReport = useRecoilValue(isReportState);
  const { allocationPanelType } = useContext(AllocationPanelContext);
  const isHistorical = allocationPanelType === 'historical read-only';
  return (
    <StudioAllocatorSubjectSection>
      <div className="flex items-center justify-between gap-[16px]">
        <StudioAllocatorSubjectSelector disabled={disabled} selectorMaxWidth={width - 49} />
        <ClosePanelButton onClose={onClose} />
      </div>
      {!isHistorical && !isPrivate && !isReport && !hideCompareLink && <CompareLink disabled={disabled} />}
    </StudioAllocatorSubjectSection>
  );
};

const CompareLink = ({ disabled }: { disabled: boolean }) => {
  const [compareOpen, setCompareOpen] = useRecoilState(compareColumnOpen);
  const onLinkClick = () => setCompareOpen(!compareOpen);

  return (
    <div style={{ textAlign: 'right' }}>
      <LinkText onClick={onLinkClick} disabled={disabled}>
        {compareOpen ? 'Hide Compare' : 'Compare Versions'}
      </LinkText>
    </div>
  );
};

const LinkText = styled.button`
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
`;
