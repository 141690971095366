import React from 'react';
import styled from 'styled-components';
import type { Fund, Portfolio } from 'venn-api';
import { findParent, normalizePortfolio, recursiveUpdateAllocations, updateNode } from 'venn-utils';
import type { DropPosition } from './AllocationTreeItem';
import AllocationTreeItem from './AllocationTreeItem';
import { TreeItemUpdateType } from './AllocationUtils';
import PrintContainerDimensions from '../print/PrintContainerDimensions';
import DragOverlay from './drag-and-drop/DragOverlay';
import { cloneDeep } from 'lodash';
import type { DeprecatedIncorrectlyDefinedItemPercentageProps } from './ItemAllocation';

export interface AllocationTreeProps extends DeprecatedIncorrectlyDefinedItemPercentageProps {
  portfolio: Portfolio;
  selectedStrategyId: number;
  compareLoading: boolean;
  allUpdatedFunds?: Map<string, Fund>;
  allOriginalNodes: Map<number, Portfolio>;
  allCompareNodes: Map<number, Portfolio>;
  allGhostChildren: Map<number, Portfolio[]>;
  hideCompareValue: boolean;
  isTradesView?: boolean;
  onUpdatePortfolio: (updatedPortfolio: Portfolio, updateType: TreeItemUpdateType) => void;
  onSelectStrategy?: (selectedStrategy: Portfolio) => void;
  onDragFinished?: () => void;
  setIsDragging?: (isDragging: boolean) => void;
  updateFund?: (fundId: string) => Promise<Fund | undefined>;
  hasAccessToCompare?: boolean;
  hideComparisonColumn: boolean;
}

interface AllocationTreeState {
  draggedNode?: Portfolio;
  initialX?: number;
  initialY?: number;
}

export default class AllocationTree extends React.PureComponent<AllocationTreeProps, AllocationTreeState> {
  dragListener?: () => void = undefined;

  state: AllocationTreeState = {
    draggedNode: undefined,
    initialX: undefined,
    initialY: undefined,
  };

  componentWillUnmount() {
    this.dragListener && window.removeEventListener('mouseup', this.dragListener);
  }

  setDraggedNode = (draggedNode?: Portfolio) => {
    this.props.setIsDragging?.(!!draggedNode);

    if (!draggedNode) {
      this.setState({
        draggedNode: undefined,
        initialX: undefined,
        initialY: undefined,
      });
      return;
    }

    this.setState({ draggedNode });
  };

  onDrag = (event: React.MouseEvent<HTMLElement>, strategy: Portfolio) => {
    const { clientX, clientY } = event;
    this.setDraggedNode(strategy);
    this.setState({
      draggedNode: strategy,
      initialX: clientX,
      initialY: clientY,
    });

    this.dragListener = () => {
      this.dragListener && window.removeEventListener('mouseup', this.dragListener);
      this.props.onDragFinished?.();
      this.dragListener = undefined;
      this.setDraggedNode(undefined);
    };
    window.addEventListener('mouseup', this.dragListener);
  };

  onDrop = (dropOnNode: Portfolio, positionedAs: DropPosition) => {
    const { draggedNode } = this.state;
    if (!draggedNode) {
      return;
    }

    const { portfolio } = this.props;
    let updatedPortfolio = cloneDeep(portfolio);

    // 1. Remove the node from its old parent
    const oldParent = findParent(portfolio, draggedNode);
    const oldParentId = oldParent ? oldParent.id : undefined;
    updatedPortfolio = updateNode(updatedPortfolio, oldParentId, (node: Portfolio) => {
      const removeAtIdx = node.children.findIndex((child: Portfolio) => child.id === draggedNode?.id);
      const updatedChildren = [...node.children];
      updatedChildren.splice(removeAtIdx, 1);
      return {
        ...node,
        children: updatedChildren,
      };
    });

    // 2. Add the node under its new parent
    const newParent =
      positionedAs === 'FIRST_CHILD' || positionedAs === 'LAST_CHILD' ? dropOnNode : findParent(portfolio, dropOnNode);
    const newParentId = newParent ? newParent.id : undefined;
    updatedPortfolio = updateNode(updatedPortfolio, newParentId, (node: Portfolio) => {
      const addAtIdx =
        positionedAs === 'FIRST_CHILD'
          ? 0
          : positionedAs === 'LAST_CHILD'
            ? node.children.length
            : node.children.findIndex((child: Portfolio) => child.id === dropOnNode.id) + 1;
      const updatedChildren = [...node.children];
      updatedChildren.splice(addAtIdx, 0, draggedNode);
      return {
        ...node,
        children: updatedChildren,
      };
    });

    // 3. Update portfolio allocations
    updatedPortfolio = recursiveUpdateAllocations(updatedPortfolio);

    // 4. Normalize portfolio (remove duplicate funds from under the same strategy)
    updatedPortfolio = normalizePortfolio(updatedPortfolio);

    this.setDraggedNode(undefined);
    this.props.onUpdatePortfolio(
      updatedPortfolio,
      oldParentId !== newParentId ? TreeItemUpdateType.DRAG_N_DROP : TreeItemUpdateType.DRAG_N_DROP_REORDER_ONLY,
    );
  };

  render() {
    const {
      portfolio,
      selectedStrategyId,
      compareLoading,
      hideCompareValue,
      allUpdatedFunds,
      allOriginalNodes,
      allCompareNodes,
      allGhostChildren,
      onUpdatePortfolio,
      onSelectStrategy,
      isPercentageMode,
      baseAllocation,
      isTradesView,
      orignalBaseAllocation,
      updateFund,
      hideComparisonColumn,
    } = this.props;

    const { draggedNode, initialX, initialY } = this.state;

    const onSelectStrategyCallback =
      onSelectStrategy === undefined
        ? undefined
        : (selected: Portfolio | undefined) => onSelectStrategy(selected ?? portfolio);
    const compareStrategy = compareLoading
      ? undefined
      : allCompareNodes.has(portfolio.id)
        ? allCompareNodes.get(portfolio.id)
        : undefined;
    const compareStrategyAllocation = compareStrategy?.allocation;
    return (
      <Tree>
        {draggedNode && <DragOverlay initialX={initialX ?? 0} initialY={initialY ?? 0} portfolioNode={draggedNode} />}
        <PrintContainerDimensions>
          {({ width }) => (
            <AllocationTreeItem
              totalWidth={width}
              strategy={portfolio}
              selectedStrategyId={selectedStrategyId}
              onSelectStrategy={onSelectStrategyCallback}
              compareStrategy={compareStrategy}
              compareLoading={compareLoading}
              allOriginalNodes={allOriginalNodes}
              allCompareNodes={allCompareNodes}
              allGhostChildren={allGhostChildren}
              allUpdatedFunds={allUpdatedFunds}
              hideCompareValue={hideCompareValue}
              isRoot
              onStrategyUpdate={onUpdatePortfolio}
              draggedNode={draggedNode}
              onDrag={this.onDrag}
              onDrop={this.onDrop}
              isPercentageMode={isPercentageMode}
              baseAllocation={baseAllocation}
              orignalBaseAllocation={orignalBaseAllocation}
              secondaryTotal={compareStrategyAllocation}
              isTradesView={isTradesView}
              updateFund={updateFund}
              hasAccessToCompare={this.props.hasAccessToCompare}
              hideComparisonColumn={hideComparisonColumn}
              rootName={portfolio.name}
            />
          )}
        </PrintContainerDimensions>
      </Tree>
    );
  }
}

const Tree = styled.div`
  margin-top: 8px;
  padding-bottom: 60px;
  position: relative;
`;
